import React, {Fragment, useState} from 'react';
import {Col, Row, Button, Form, InputGroup, FormControl} from "react-bootstrap";
import './CheckoutForm.css'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import PaymentResult from "./PaymentResult";
import Amplify, { API } from 'aws-amplify';

// const myAPI = "apidc2e093a";
// const path = "/items"

const myAPI = "apidc2e093a";
const path = "/items"

const CheckoutForm = () => {
    const [mainInputs, setMainInputs] = useState({
        "amount": "",
        "email": "",
        "category": "Protection Equipment"
    });
    const [businessInput, setBusinessInput] = useState({
        "city": "",
        "address": "",
        "business_name": "",
        "province": "Saskatchewan"
    });
    const [isBusiness, setIsBusiness] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [error, setError] = useState("");

    const [messageBusiness, setMessageBusiness] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const updatePageAfterClose =() => {
        setModalShow(false)
        window.location.reload();
    }

    const check_amount_input = (value) => {
            const pattern = /^\d+$/;
            const is_valid_number = pattern.test(value)
            return is_valid_number
    }

    const check_email = (value) => {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        const is_valid_email = pattern.test( value )
        return is_valid_email;
    }

    const stripeTokenHandler = async (token) => {
        const paymentData = {token: token.id};

        const myInit = {
            body: { items: { "token": paymentData.token, "metadata": mainInputs, businessInput} },
            headers: { "Content-Type": "application/json" },
        };

        API
            .post(myAPI, path, myInit)
        // fetch(myAPI + path, {
        //     method: "POST",
        //     headers: { "Content-Type": "application/json" },
        //     body: JSON.stringify({ items: { "token": paymentData.token, "metadata": mainInputs, businessInput} }),
        // })
        .then( response => {
            // console.log(response.ok,  " ok or nah");
            // console.log(response === "success", " this is the response")
            setModalShow(true)
            setIsLoading(false)
            // nodejs server
            //     if (!response.ok) { throw response }
            //     return response.text()  //we only get here if there is no error
            // lambda server
            // return response.text()  //we only get here if there is no error

            })
            .catch( err => {
                setIsLoading(false)
                // err.text().then( errorMessage => {
                    console.log("error server response:", err )
                    return setError("Something went wrong. Please try again later")
            })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("")
        setMessageBusiness("")
        if (!check_email(mainInputs.email)) {
            console.log(" hehe, not valid")
            return setError("Please, provide a valid email")
        }

        if (!check_amount_input(mainInputs.amount)) {
            console.log(" hehe, not valid amount")
            return setError("Please, provide a valid amount to donate")
        }

        if (isBusiness) {
            if (businessInput.address.trim().length < 1 ||
                businessInput.business_name.trim().length < 1 ||
                businessInput.city.trim().length < 1
            ){
                return setMessageBusiness("Invalid Business Information. \n " +
                    "Press the Company button to close it, or \n Provide the correct information")
            }
        }
        setError("")
        setMessageBusiness("")

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setIsLoading(true)
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);

        if (result.error) {
            // Show error to your customer.
        } else {
            // Send the token to your server.
            // This function does not exist yet; we will define it in the next step.
            try {
                await stripeTokenHandler(result.token, mainInputs);
            } catch (e) {
                setError('Failed saving user')
            }
        }
    };

    const handleBusinessInput = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        setBusinessInput(values => ({...values, [name]: value}))
    }

    const handleMainInput = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        setMainInputs(values => ({...values, [name]: value}))
    }

    return (
        <Fragment>

            <Form id="payment-form" onSubmit={handleSubmit} style={{textAlign:"left"}} >
                {/*<PaymentElement id="payment-element" />*/}
                <div id="cardElement" className="form-control" style={{height: '2.4em', paddingTop: '.7em'}}>
                    <CardElement/>
                </div>

                {/*<Row className="mb-3">*/}
                    <InputGroup className="mb-3" style={{marginTop:'10%'}}>
                        <InputGroup.Text>$</InputGroup.Text>
                        <FormControl aria-label="Amount you would like to donate"
                                     placeholder="Amount you would like to donate"
                                     name="amount"
                                     value={mainInputs.amount}
                                     onChange={handleMainInput}
                        />
                        <InputGroup.Text>CAD</InputGroup.Text>
                    </InputGroup>

                <Form.Group as={Col} controlId="formGridState">
                    <Form.Label className={'text_label'}>Choose category for your donation</Form.Label>
                    <Form.Select
                                // defaultValue="Protection Equipment"
                                 name="category"
                                 value={mainInputs.category}
                                 onChange={handleMainInput}
                    >
                        <option>Protection Equipment</option>
                        <option>Military Supplies</option>
                        <option>Medical Supplies</option>
                        <option>Animal Rescue K-9</option>
                    </Form.Select>
                </Form.Group>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label className={'text_label'}>Email</Form.Label>
                        <Form.Control type="email"
                                      placeholder="Enter email"
                                      name="email"
                                      value={mainInputs.email}
                                      onChange={handleMainInput}
                        />
                        <p>{error}</p>
                    </Form.Group>
                </Row>

                <Row className={"row_button"}>
                    <Button id='submit' type="submit" disabled={!stripe || !elements}
                            className={'myButton'}
                    >
                        {/*Pay*/}
                        <span id="button-text">
                            {isLoading ? <div className={"spinner"} id="spinner"></div> : "Pay now"}
                        </span>
                    </Button>
                </Row>
                <Row className="mb-3">
                    <Button variant="primary" onClick={() => setIsBusiness(!isBusiness)} style={{marginTop:'5%'}}>
                        For Company Donations: Press For Tax Receipt
                    </Button>

                </Row>
                {isBusiness ?
                    <Fragment>
                        <p>{messageBusiness}</p>
                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label className={'text_label'}>Business Name</Form.Label>
                            <Form.Control
                                placeholder="..."
                                name="business_name"
                                value={mainInputs.business_name}
                                onChange={handleBusinessInput}

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formGridAddress1">
                            <Form.Label>Billing Address</Form.Label>
                            <Form.Control
                                placeholder="1234 Main St"
                                name="address"
                                value={mainInputs.address}
                                onChange={handleBusinessInput}
                            />
                        </Form.Group>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    placeholder="Saskatoon"
                                    name="city"
                                    value={mainInputs.city}
                                    onChange={handleBusinessInput}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Province</Form.Label>
                                <Form.Select
                                    name="province"
                                    value={mainInputs.province}
                                    onChange={handleBusinessInput}
                                >
                                    <option>Saskatchewan</option>
                                    <option>Alberta</option>
                                    <option>British Columbia</option>
                                    <option>Manitoba</option>
                                    <option>Ontario</option>
                                    <option>New Brunswick</option>
                                    <option>Newfoundland and Labrador</option>
                                    <option>Nova Scotia</option>
                                    <option>Prince Edward Island</option>
                                    <option>Quebec</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </Fragment>
                    : null
                }

            </Form>

            <PaymentResult
                show={modalShow}
                onHide={updatePageAfterClose}
            />
        </Fragment>
    );
};
export default CheckoutForm