import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import './About.css';


const About = () => {
    return (
        <Container fluid className={"overlay_about py-5"} id="about">
            <Row>
                <Col></Col>
                {/*<Col xs={4}  style={{background:"red"}}>*/}
                <Col xs={4} className={"about"}>
                    <h1 className={"h1"}>About Us</h1>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                {/*<Col xs={4}  style={{background:"red"}}>*/}
                <Col xs={4}>
                    <hr style={{marginLeft: "auto", marginRight: "auto"}}/>
                </Col>
                <Col></Col>
            </Row>
            <Row style={{marginTop:"0%"}}>
                <Col></Col>
                {/*<Col xs={10} style={{background:"red"}}>*/}
                <Col xs={10} className={"about_text"}>
                    <p className={"p"}>We are a group of volunteers with a deep connection to Ukraine, who wants to help people and animals that are currently affected by war and its aftermath.
                        <br/>
                        The war in Ukraine has taught us the importance of standing together for what we believe in. It has been a strong motivator to establish our non-profit organization Protect Me International Relief.
                    <br/>
                        Presently, all of our resources and efforts are focused on aid for Ukraine in resisting the Russian aggression. We are able to help by using 100% of your donations to provide the necessary financial, humanitarian, medical and military aid. We work in cooperation with our volunteers in Ukraine and abroad.
                   <br/>
                        Protect Me International Relief does not charge administrative fees and therefore 100% of your donations are being sent for the cause.
                    <br/>
                    <br/>
                        We stand with Ukraine! Join us in our mission!
                    <br/>
                        #StandWithUkraine!
                    </p>




                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};
export default About;