/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apidc2e093a",
            "endpoint": "https://x91sy4gdzc.execute-api.ca-central-1.amazonaws.com/staging",
            "region": "ca-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ca-central-1:e5ae98b6-95c9-45c2-9636-fa3be1a358eb",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_vAzf1bvtk",
    "aws_user_pools_web_client_id": "5gatltgporjoikbun6qu64mqdr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
