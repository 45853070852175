import React from 'react';
import {Button, Modal} from "react-bootstrap";


import './CheckoutForm.css'


const PaymentResult = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton style={{fontFamily: 'Open Sans sans-serif'}}>
                <Modal.Title id="contained-modal-title-vcenter">
                    We Thank you for Your Donation!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <br/>
                {/*<h4>Centered Modal</h4>*/}
                <h6 style={{fontFamily: 'Open Sans sans-serif', fontSize: '18px', letterSpacing: '1px'}}>
                    You will receive an email with the confirmation soon.
                </h6>
                <br/>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default PaymentResult