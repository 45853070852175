import React, {Fragment, useState} from "react";
import {Card, Carousel, Col, Container, Modal, Row} from "react-bootstrap";
import './Statement.css';
import make_don from '../../images/make_don.png';
import second_photo from '../../images/second_photo.png';
import many_ribbons from '../../images/many_ribbons.png';
import small_logo from '../../images/small_logo.png';
import full_screen from '../../images/full_screen.png';
import ModalComponent from "../Modal/ModalComponent";

const StatementMobile = () => {
    const [index, setIndex] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [indexItem, setIndexItem] = useState(0);

    const handleSelectItem = (bool_show_modal, selectedIndex) => {
        setIndexItem(selectedIndex);
        setModalShow(true)
    };

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    // const clicked_card = () => {
    //
    // }


    return (
        <Fragment>
            <Container fluid className={"overlay_statement"} id="statements">

            <Row style={{maxWidth:"100%", margin:"0"}}>
                <Col xs={12} className={"main_col d-flex justify-content-center"}>

                <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item
                className={"carousel_item"}
                onClick={() => handleSelectItem(true, 1)}>
                <img
                    className="d-block w-100"
                    src={make_don}
                    alt="First slide"
                />
                <Card.ImgOverlay
                    style={{backgroundColor:"rgb(135, 206, 235, 0.9)"}}

                    className={"top_image_even"}>

                    <Carousel.Caption className={"text_card"}
                        >
                        Where to Buy Ribbons
                        <br/>
                        <hr style={{marginLeft: "auto", marginRight: "auto"}}/>
                        Ribbons Locations
                    </Carousel.Caption>

                </Card.ImgOverlay>

            </Carousel.Item>
            <Carousel.Item
                className={"carousel_item"}>
                <img
                    className="d-block w-100"
                    src={full_screen}
                    alt="Second slide"
                />

                <Card.ImgOverlay
                    style={{backgroundColor:"rgb(135, 206, 235, 0.9)"}}

                    className={"top_image_even"}>
                    <Carousel.Caption className={"text_card"}>
                        To Order Ribbons
                        <br/>
                        <hr style={{marginLeft: "auto", marginRight: "auto"}}/>
                        Please send us an Email
                    </Carousel.Caption>
                </Card.ImgOverlay>
            </Carousel.Item>
            <Carousel.Item
                className={"carousel_item"}
                onClick={() => handleSelectItem(true, 3)}>
                <img
                    className="d-block w-100"
                    src={small_logo}
                    alt="Third slide"
                />

                <Card.ImgOverlay
                    style={{backgroundColor:"rgb(135, 206, 235, 0.9)"}}

                    className={"top_image_even"}>
                    <Carousel.Caption
                        className={"text_card"}
                    >
                        Photos of The Items
                        <br/>
                        <hr style={{marginLeft: "auto", marginRight: "auto"}}/>
                        Purchased For Ukraine
                    </Carousel.Caption>
                </Card.ImgOverlay>
            </Carousel.Item>

        </Carousel>
                </Col>
            </Row>

    <ModalComponent
        show={modalShow}
        indexClicked = {indexItem}
        onHide={() => setModalShow(false)}
    />
            </Container>

        </Fragment>


        // <Container fluid className={"overlay_statement"} id="statements">
        //     <Row style={{maxWidth:"100%", margin:"0"}}>
        //         {/*<Col></Col>*/}
        //         <Col xs={12} className={"main_col d-flex justify-content-center"}>
        //             {/*style={{ width: '18rem', height:"18rem" }}*/}
        //
        //             <Card
        //                 onClick={() => handleSelect(true, 1)}
        //                 type='button'
        //                 className={"card_statement text-dark"}
        //                 style={{backgroundColor:"rgb(245, 245, 245, 0.4)"}}
        //                 // onClick={() => setModalShow(true)}
        //             >
        //                 <Card.Img src={make_don} alt="Card image" style={{padding:"0", width:"100%", height:'100%'}}/>
        //                 <Card.ImgOverlay className={"top_image_odd text-dark"} >
        //                     <Card.Title className={"text_card"}>
        //                         Where to Buy Ribbons
        //                         <br/>
        //                         <hr style={{marginLeft: "auto", marginRight: "auto"}}/>
        //                         Ribbons Locations
        //                     </Card.Title>
        //                     {/*<Card.Text className={"text_card"}>*/}
        //                     {/*    This is a wider card with supporting text below as a natural lead-in to*/}
        //                     {/*    additional content. This content is a little bit longer.*/}
        //                     {/*</Card.Text>*/}
        //                 </Card.ImgOverlay>
        //             </Card>
        //             <Card  className="card_statement  text-white">
        //                 <Card.Img src={full_screen} alt="Card image" style={{padding:"0", width:"100%", height:'100%'}}/>
        //                 <Card.ImgOverlay className={"top_image_even"}>
        //                     <Card.Title className={"text_card"}>
        //                         To Order Ribbons
        //                         <br/>
        //                         <hr style={{marginLeft: "auto", marginRight: "auto"}}/>
        //                         Please send us an Email
        //                     </Card.Title>
        //                     {/*<Card.Text className={"text_card"}>*/}
        //                     {/*    */}
        //                     {/*</Card.Text>*/}
        //                     {/*<Card.Text>Last updated 3 mins ago</Card.Text>*/}
        //                 </Card.ImgOverlay>
        //             </Card>
        //             <Card
        //                 type='button'
        //                 onClick={() => handleSelect(true, 3)}
        //                 // onClick={() => setModalShow(true, 3)}
        //                 className="card_statement text-dark"
        //                 style={{backgroundColor:"rgb(245, 245, 245, 0.4)"}}
        //             >
        //                 <Card.Img src={small_logo} alt="Card image" style={{padding:"0", width:"100%", height:'100%'}} />
        //                 <Card.ImgOverlay className={"top_image_odd text-dark"}>
        //                     <Card.Title className={"text_card"}>
        //                         Photos of The Items
        //                         <br/>
        //                         <hr style={{marginLeft: "auto", marginRight: "auto"}}/>
        //                         Purchased For Ukraine
        //                     </Card.Title>
        //                     {/*<Card.Text className={"text_card"}>*/}
        //                     {/*    This is a wider card with supporting text below as a natural lead-in to*/}
        //                     {/*    additional content. This content is a little bit longer.*/}
        //                     {/*</Card.Text>*/}
        //                     {/*<Card.Text>Last updated 3 mins ago</Card.Text>*/}
        //                 </Card.ImgOverlay>
        //             </Card>
        //             {/*<Card className="card_statement bg-dark text-white">*/}
        //             {/*    <Card.Img src="holder.js/100px270" alt="Card image" />*/}
        //             {/*    <Card.ImgOverlay>*/}
        //             {/*        <Card.Title>Card title</Card.Title>*/}
        //             {/*        <Card.Text>*/}
        //             {/*            This is a wider card with supporting text below as a natural lead-in to*/}
        //             {/*            additional content. This content is a little bit longer.*/}
        //             {/*        </Card.Text>*/}
        //             {/*        <Card.Text>Last updated 3 mins ago</Card.Text>*/}
        //             {/*    </Card.ImgOverlay>*/}
        //             {/*</Card>*/}
        //
        //
        //         </Col>
        //     </Row>
        //
        //     <ModalComponent
        //         show={modalShow}
        //         indexClicked = {index}
        //         onHide={() => setModalShow(false)}
        //     />
        // </Container>
    );
};
export default StatementMobile;