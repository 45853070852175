import React from 'react';
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import './TotalAmount.css';
import total_amount from '../../images/total_amount.png';
// import total_amount_no_bg from '../../images/may_25_2022_png.png';
import total_amount_no_bg from '../../images/dec_d.png';
const TotalAmount = () => {
    return (
        // <div style={{background:"black"}}>
        //     hello
        // </div>
        <Container fluid className={"overlay_total_amount  py-5"} id="summary">
            {/*<Row>*/}
            {/*    <Col></Col>*/}
            {/*    /!*<Col xs={4}  style={{background:"red"}}>*!/*/}
            {/*    <Col xs={4}>*/}
            {/*        <img src={total_amount} alt="Logo" />*/}
            {/*    </Col>*/}
            {/*    <Col></Col>*/}
            {/*</Row>*/}
            <Row style={{marginTop:"0%"}}>
                <Col></Col>
                {/*<Col xs={10} style={{background:"red"}}>*/}
                <Col xs={10} className={"main_col d-flex justify-content-center"}>
                    <img src={total_amount_no_bg} alt="Logo" className={"image_total_amt"}/>
                    {/*<img src={total_amount} alt="Logo" />*/}
                    {/*<Container>*/}
                    {/*<Navbar variant="light">*/}
                        {/*<Navbar>*/}

                        {/*<Row>*/}
                        {/*<Col>*/}
                        {/*    <Navbar.Brand href="#home">Navbar</Navbar.Brand>*/}
                        {/*</Col>*/}

                        {/*<Nav className={"nav_link"}>*/}
                        {/*    <Col  className={"nav_link_col"}>*/}
                        {/*        <Nav.Link href="#home">Home</Nav.Link>*/}
                        {/*        <Nav.Link href="#about">About</Nav.Link>*/}
                        {/*        <Nav.Link href="#donate">Donate</Nav.Link>*/}
                        {/*        <Nav.Link href="#statements">Statements</Nav.Link>*/}
                        {/*        <Nav.Link href="#contact">Contact</Nav.Link>*/}
                        {/*    </Col>*/}
                        {/*    /!*<Col xs={2}  style={{background:"green", textAlign:"right"}} >*!/*/}
                        {/*    <Col xs={2}  style={{float:"right"}} >*/}
                        {/*        <Nav.Link href="facebook" style={{float:"right"}}><FaInstagram/></Nav.Link>*/}
                        {/*        <Nav.Link href="facebook" style={{float:"right"}}><FaFacebookF/></Nav.Link>*/}
                        {/*    </Col>*/}
                        {/*</Nav>*/}

                        {/*</Row>*/}

                    {/*</Navbar>*/}
                    {/*</Container>*/}
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
};
export default TotalAmount;
