import React, {Fragment} from "react";
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import './Contact.css';
import logo from '../../images/no_bg.png';
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import {useMediaQuery} from "react-responsive";
import ContactMobile from "./ContactMobile";



const Contact = () => {
    return (
                <footer>
                    <Container fluid className={"overlay_contact py-4"} id="contact">
                        <Row>
                            <Col></Col>
                            {/*<Col xs={4}  style={{background:"red"}}>*/}
                            {/*<Col md={10} xs={10}>*/}
                            <Col xs={10} md={10} className="logo-part">
                                <h5> Contact</h5>
                                <p>Protect Me International Relief
                                    <br/>
                                    <br/>
                                    7-510 44th Street East,<br/> Saskatoon, SK, S7K 0W1
                                    <br/>
                                    <br/>
                                    Email: protectme.intrelief@gmail.com
                                </p>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                            <Col></Col>
                            <Col xs={10} md={10}>
                                <br/><br/>
                                <h6> Follow us</h6>
                                <div className="social">
                                    <a
                                        href="https://www.facebook.com/Protect-Me-International-Relief-106159615405297"
                                        className="fa fa-instagram">
                                        <FaFacebookF/></a>
                                </div>
                                <br/>
                                <p className="text-center">
                                    Copyright @2021 | <br/>
                                    Designed and Created by <br/> Protect Me International Relief -
                                    <Nav.Link
                                        style={{all:"unset", cursor:"pointer"}}
                                        href="https://www.linkedin.com/in/yaroslav-dobushovsky-11211540/"
                                    > Yaroslav D.</Nav.Link>
                                </p>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </footer>
    );
};
export default Contact;


