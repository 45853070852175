import React from "react";
import {Col, Container, Row, Nav, Button} from "react-bootstrap";
import './BackToTop.css';
import {FaChevronUp} from 'react-icons/fa';



const BackToTop = () => {
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };
    return (
            <Container fluid className={"overlay_back_to_top py-2"}>
                <Row className={"main_body"}>
                    <Col></Col>
                    <Col xs={6} md={6} className="text-center">
                        {/*<Button>*/}
                        <br/>
                        <FaChevronUp type='button' className="text-center the_arrow" onClick={scrollToTop}/>
                        {/*</Button>*/}
                            <p className={"p_back_to_top"}>
                            Back to Top
                                {/*"src": "favicon.ico",*/}
                        </p>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
    );
};
export default BackToTop;


