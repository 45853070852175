import React, {Fragment} from "react";
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import './Contact.css';
import logo from '../../images/no_bg.png';
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import {useMediaQuery} from "react-responsive";
import ContactMobile from "./ContactMobile";



const Contact = () => {

    const isTabletOrMobile = useMediaQuery({ minWidth: 992 })

    return (
        <Fragment>
            { isTabletOrMobile ?
        <footer>
        <Container fluid className={"overlay_contact py-4"} id="contact">
            <Row>
                <Col></Col>
                {/*<Col xs={4}  style={{background:"red"}}>*/}
                {/*<Col md={10} xs={10}>*/}
                    <Col xs={3} md={3} className="logo-part">
                        <h5> Contact</h5>
                        <p>Protect Me International Relief
                            <br/>
                            <br/>
                        7-510 44th Street East,<br/> Saskatoon, SK, S7K 0W1
                            <br/>
                            <br/>
                        Email: protectme.intrelief@gmail.com</p>
                    </Col>

                    <Col xs={6} md={6}>
                        <div className="middle_contact">
                                <Nav.Link href="/welcome">Home</Nav.Link>
                                <Nav.Link href="/about">About</Nav.Link>
                                <Nav.Link href="#donate">Donate</Nav.Link>
                                <Nav.Link href="/summary">Summary</Nav.Link>
                                <Nav.Link href="/statements">Statements</Nav.Link>
                                <Nav.Link href="/contact">Contact</Nav.Link>
                        <p className="text-center">
                            Copyright @2021 | Designed and Created by <br/> Protect Me International Relief -
                            <Nav.Link
                                style={{all:"unset", cursor:"pointer"}}
                                href="https://www.linkedin.com/in/yaroslav-dobushovsky-11211540/"
                        > Yaroslav D.</Nav.Link>
                        </p>
                        </div>
                    </Col>
                    <Col xs={1} md={1}>
                        <h6> Follow us</h6>
                        <div className="social">
                            {/*<Nav.Link*/}
                            {/*          href="https://www.facebook.com/Protect-Me-International-Relief-106159615405297"*/}
                            {/*          className="fa fa-facebook"*/}
                            {/*          style={{paddingRight:"50%"}}>*/}
                            {/*    <FaInstagram/></Nav.Link>*/}
                            <a
                                href="https://www.facebook.com/Protect-Me-International-Relief-106159615405297"
                                className="fa fa-instagram">
                                <FaFacebookF/></a>
                        </div>
                    </Col>
                <Col></Col>
            </Row>
        </Container>
        </footer>
            :
            <ContactMobile/>
            }
        </Fragment>

    );
};
export default Contact;


