import React, {Fragment} from "react";
import {Button, Modal} from "react-bootstrap";
import locations from '../../images/locations.png';
import "./ModalComponent.css";
function ModalComponent(props) {
    // setIndex(selectedIndex);
    // setModalShow(true)

    const showLocations = () => {

        return (
            <Modal
                className={"locations_ribbon"}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/*<Modal.Header closeButton>*/}
                {/*    <Modal.Title id="contained-modal-title-vcenter">*/}
                {/*        Modal heading*/}
                {/*    </Modal.Title>*/}
                {/*</Modal.Header>*/}
                <Modal.Body>
                    {/*<h4>Centered Modal</h4>*/}
                    {/*<p>*/}
                    <img src={locations} alt="locations"/>
                    {/*Cras mattis consectetur purus sit amet fermentum. Cras justo odio,*/}
                    {/*dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac*/}
                    {/*consectetur ac, vestibulum at eros.*/}
                    {/*</p>*/}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const showPhotos = () => {
        return(
            <Modal
                className={"show_photos"}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Content is coming ...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*<h4>Centered Modal</h4>*/}
                    <p>
                        If you would like to see statements and photos, follow us on facebook or send us an email
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    return (

        <Fragment>
            {props.indexClicked === 1 ? showLocations() : showPhotos()}
        </Fragment>
        // <Modal
        //     className={"locations_ribbon"}
        //     {...props}
        //     size="lg"
        //     aria-labelledby="contained-modal-title-vcenter"
        //     centered
        // >
        //     <Modal.Header closeButton>
        //         <Modal.Title id="contained-modal-title-vcenter">
        //             Modal heading
        //         </Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //         <h4>Centered Modal</h4>
        //         <p>
        //             Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
        //             dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
        //             consectetur ac, vestibulum at eros.
        //         </p>
        //     </Modal.Body>
        //     <Modal.Footer>
        //         <Button onClick={props.onHide}>
        //             Close
        //         </Button>
        //     </Modal.Footer>
        // </Modal>
    );
}
export default ModalComponent;