import React, {useState} from 'react';
import './Navigation.css';
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import { FaBars, FaFacebookF } from 'react-icons/fa';
import logo from '../../images/no_bg.png';
import logo_ribbon from '../../images/small_logo.png';
import NavigationSlider from "./NavigationSlider";
const NavigationMobile = () => {
    const [slider, setSlider] = useState(false)
    const handleClick = () => {
        setSlider(!slider)
    }
    function openNav() {
        document.getElementById("mySidenav").style.width = "80%";
    }

    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }
    return (
        <Container fluid className={"overlay"} id="welcome">
            <Row style={{height:"50%"}}>
                <Col xs={2}>
                    <div className={"nav_link_social"}>
                        <a
                            href="https://www.facebook.com/Protect-Me-International-Relief-106159615405297"
                            // className="fa_icon fa-facebook"
                            style={{
                                float:"right",
                                marginTop:"5rem",
                                fontSize:"30px",
                                color:"rgb(135, 206, 235)"
                            }}>

                            <FaFacebookF/>
                        </a>
                    </div>
                </Col>
                {/*<Col xs={4}  style={{background:"red"}}>*/}
                <Col xs={8} className={"logo_phone"}>
                    <img src={logo_ribbon} alt="Logo"
                         style={{marginTop:"3rem"}}
                    />
                </Col>
                <Col xs={2}>
                    <div
                        className="menu_bar"
                        style={{float:"left",
                            marginTop:"5rem",
                            fontSize:"30px"}}>

                        <div id="mySidenav" className="sidenav">
                            <a href="javascript:void(0)" className="closebtn" onClick={()=>closeNav()}>&times;</a>
                            <a href="/welcome" className={"links_itself"} onClick={()=>closeNav()}>Home</a>
                            <a href="/about" className={"links_itself"} onClick={()=>closeNav()}>About</a>
                            <a href="#donate" className={"links_itself"} onClick={()=>closeNav()}>Donate</a>
                            <a href="/summary" className={"links_itself"} onClick={()=>closeNav()}>Summary</a>
                            <a href="/statements" className={"links_itself"} onClick={()=>closeNav()}>Statements</a>
                            <a href="/contact" className={"links_itself"} onClick={()=>closeNav()}>Contact</a>
                        </div>

                        {/*<h2>Animated Sidenav Example</h2>*/}
                        {/*<p>Click on the element below to open the side navigation menu.</p>*/}
                        {/*<span style={{fontSize:"30px",cursor:"pointer"}} onClick={()=>openNav()}>&#9776; open</span>*/}
                        <FaBars style={{fontSize:"30px",cursor:"pointer", color:"rgb(135, 206, 235)"}} onClick={()=>openNav()}/>
                        {/*<FaBars onClick={handleClick}/>*/}
                        {/*<NavigationSlider/>*/}
                        {/*{slider ? <NavigationSlider/> : null }*/}
                    </div>
                </Col>
            </Row>
            <Row style={{height:"50%"}}>
                {/*<Col></Col>*/}
                {/*<Col xs={4}  style={{background:"red"}}>*/}
                <Col   className={"logo_phone"}>
                    <img src={logo} alt="Logo" />
                </Col>
                {/*<Col></Col>*/}
            </Row>
        </Container>
    );
};
export default NavigationMobile;