import React, {Fragment} from 'react';
import './Navigation.css';
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import logo from '../../images/no_bg.png';
import {useMediaQuery} from "react-responsive";
import NavigationMobile from "./NavigationMobile";
const Navigation = () => {
    const isTabletOrMobile = useMediaQuery({ minWidth: 992 })

    return (
        // <div style={{background:"black"}}>
        //     hello
        // </div>
        <Fragment>
        { isTabletOrMobile ?

        <Container fluid className={"overlay"} id="welcome">

            <Row style={{height:"80%"}}>
                <Col></Col>
                {/*<Col xs={4}  style={{background:"red"}}>*/}
                <Col xs={8} style={{marginTop: "-7%"}}  className={"logo_phone"}>
                    <img src={logo} alt="Logo" />
                </Col>
                <Col></Col>
            </Row>
            <Row style={{marginTop:"0%"}}>
                <Col></Col>
                {/*<Col xs={10} style={{background:"red"}}>*/}
                <Col xs={10}>
                    {/*<Container>*/}
                        <Navbar variant="light">
                    {/*<Navbar>*/}

                                {/*<Row>*/}
                                    {/*<Col>*/}
                                    {/*    <Navbar.Brand href="#home">Navbar</Navbar.Brand>*/}
                                    {/*</Col>*/}

                                        <Nav className={"nav_link"}>
                                            <Col  className={"nav_link_col"}>
                                                <Nav.Link href="#welcome" className={"links_itself"}>Home</Nav.Link>
                                                <Nav.Link href="#about" className={"links_itself"}>About</Nav.Link>
                                                <Nav.Link href="#donate" className={"links_itself"}>Donate</Nav.Link>
                                                <Nav.Link href="#summary" className={"links_itself"}>Summary</Nav.Link>
                                                <Nav.Link href="#statements" className={"links_itself"}>Statements</Nav.Link>
                                                <Nav.Link href="#contact" className={"links_itself"}>Contact</Nav.Link>
                                            </Col>
                                            {/*<Col xs={2}  style={{background:"green", textAlign:"right"}} >*/}
                                            <Col xs={2}  style={{float:"right"}} stylename={"social_icons"}>
                                                {/*<Nav.Link*/}
                                                {/*    href="https://www.facebook.com/Protect-Me-International-Relief-106159615405297"*/}
                                                {/*    className="fa fa-facebook"*/}
                                                {/*    style={{float:"right"}}><FaInstagram/></Nav.Link>*/}
                                                <Nav.Link
                                                    href="https://www.facebook.com/Protect-Me-International-Relief-106159615405297"
                                                    className="fa fa-facebook"
                                                    style={{float:"right"}}><FaFacebookF/></Nav.Link>
                                            </Col>
                                        </Nav>

                                {/*</Row>*/}

                        </Navbar>
                    {/*</Container>*/}
                </Col>
                <Col></Col>
            </Row>
        </Container>
                :
                <NavigationMobile/>
            }
        </Fragment>
    );
};
export default Navigation;