import React from 'react';
import './Parallax.css';
import {Card, Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import logo from '../../images/no_bg.png';
import make_don from "../../images/make_don.png";
import full_screen from "../../images/full_screen.png";
import small_logo from "../../images/no_bg.png";
import Contact from "../Contact/Contact";
const Parallax = () => {
    return (
        <Container fluid style={{background: "#f5f5f5"}}>
            <Row style={{maxWidth:"100%", margin:"0"}}>
                {/*<Col></Col>*/}
                <Col xs={12} className={"main_col d-flex justify-content-center"}>
                    <Col className="parallax">
                        <div className={"image"}>

                        </div>
                        {/*<img src={small_logo} alt="Logo" />*/}
                    </Col>
                    {/*<Col style={{height:"500px", zIndex:"1", opacity:"0.9" ,backgroundColor:"transparent"}}>*/}

                    {/*    /!*<Contact/>*!/*/}
                    {/*</Col>*/}
                    {/*<Row className={"back_to_top"}>*/}
                    {/*    Some Text*/}
                    {/*</Row>*/}
                    {/*<Col className="parallax">*/}
                    {/*</Col>*/}
                </Col>
            </Row>
        </Container>
    );
};
export default Parallax;