import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "./components/Navbar/Navigation";
import About from "./components/About/About";
import Statement from "./components/Statement/Statement";
import Donate from "./components/Donate/Donate";
import Contact from "./components/Contact/Contact";
import TotalAmount from "./components/TotalAmount/TotalAmount";
import Parallax from "./components/Parallax/Parallax";
import BackToTop from "./components/BackToTop/BackToTop";
import './index.css'
import { Routes, Route, Navigate } from "react-router-dom"
import {Nav} from "react-bootstrap";
import React, {Fragment, useEffect, useState} from "react";
import { useMediaQuery } from 'react-responsive'
import {Elements} from "@stripe/react-stripe-js";
// import CheckoutForm from "./components/Donate/CheckoutForm";
import {loadStripe} from "@stripe/stripe-js";
import Amplify, { API } from 'aws-amplify';

// const myAPI = "apidc2e093a";
// const path = "/items"
function App() {
  const isTabletOrMobile = useMediaQuery({ minWidth: 992 })
  console.log(isTabletOrMobile, "Tablet")
  // useEffect(() => {
  //   // Update the document title using the browser API
  //   console.log("Before api")
  //   API.get(myAPI, path)
  //       .then(response => console.log(response, "!!!!!!!!!!! RES"))
  //   // document.title = `You clicked ${count} times`;
  // });
  return (

      <div className="App">

        {isTabletOrMobile ?
            <Fragment>
              <Navigation/>
              <Parallax/>
              <About/>
              {/*<Elements stripe={stripePromise}>*/}
              {/*    <CheckoutForm />*/}
              {/*</Elements>*/}
              {/*<Payment/>*/}
              <Donate/>
              <TotalAmount/>
              <Statement/>
              <Parallax/>
              <BackToTop/>
              <Contact/>
            </Fragment>
            :
            <Fragment>
              <Navigation/>
              <About/>
              {/*<Payment/>*/}
              <Donate/>
              <TotalAmount/>
              <Statement/>
              <BackToTop/>
              <Contact/>
            </Fragment>
        }

        {/*{!isTabletOrMobile &&*/}
        {/*    <Fragment>*/}
        {/*        /!*<Navigation/>*!/*/}
        {/*        /!*<Parallax/>*!/*/}
        {/*        <About/>*/}
        {/*        <Donate/>*/}
        {/*        <TotalAmount/>*/}
        {/*        <Statement/>*/}
        {/*        /!*<Parallax/>*!/*/}
        {/*        <BackToTop/>*/}
        {/*        <Contact/>*/}
        {/*    </Fragment>*/}
        {/*}*/}


        {/*//     <Nav.Link href="#home" className={"links_itself"}>Home</Nav.Link>*/}
        {/*//     <Nav.Link href="#about" className={"links_itself"}>About</Nav.Link>*/}
        {/*//     <Nav.Link href="#donate" className={"links_itself"}>Donate</Nav.Link>*/}
        {/*//     <Nav.Link href="#statements" className={"links_itself"}>Statements</Nav.Link>*/}
        {/*//     <Nav.Link href="#contact" className={"links_itself"}>Contact</Nav.Link>*/}

        {/*<Routes>*/}
        {/*    <Route path="/" element={<Navigate to="home"/>} />*/}
        {/*    /!*<Parallax/>*!/*/}
        {/*    <Route path="/welcome" element={<Navigation />} />*/}

        {/*<Route path="/about" element={<About />} />*/}
        {/*    <Route path="/donate" element={<Donate />} />*/}
        {/*    <Route path="/summary" element={<TotalAmount />} />*/}
        {/*    <Route path="/statements" element={<Statement />} />*/}
        {/*    /!*<Parallax/>*!/*/}
        {/*    /!*<BackToTop/>*!/*/}
        {/*    /!*<Route path="statements" element={<BackToTop />} />*!/*/}
        {/*    <Route path="contact" element={<Contact />} />*/}
        {/*</Routes>*/}
      </div>
  );
}

export default App;
