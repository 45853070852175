import React, {Fragment} from "react";
import {Col, Container, Row, Card, Button, ListGroup, ListGroupItem} from "react-bootstrap";
import './Donate.css';
import {useMediaQuery} from "react-responsive";
import NavigationMobile from "../Navbar/NavigationMobile";
import CheckoutForm from "./CheckoutForm";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_ENV_STRIPE_PROM_TEST)

const DonateMobile = () => {
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        appearance,
    };
    return (
                <Container fluid className={"overlay_donate py-5"} id="donate">

                    <Row>
                        <Col></Col>
                        {/*<Col xs={4}  style={{background:"red"}}>*/}
                        <Col xs={4} className={"about"}>
                            <h1 className={"h1"}>Ways to Donate</h1>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        {/*<Col xs={4}  style={{background:"red"}}>*/}
                        <Col xs={4}>
                            <hr style={{marginLeft: "auto", marginRight: "auto"}}/>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row style={{marginTop:"0%"}}>
                        <Col></Col>
                        {/*<Col xs={10} style={{background:"red"}}>*/}
                        <Col xs={10} className={"about_text d-flex justify-content-center"}>
                            <Card style={{ width: '100%', marginLeft:"0", marginRight:"0" }} className={"card"}>
                                <Card.Body>
                                    <Card.Title className={"title_card"}>Credit Card</Card.Title>
                                    <Card.Text className={"main_text_card"}>
                                        <br/>
                                        Protect Me International Relief
                                        <br/>
                                        <br/>

                                    </Card.Text>
                                    <Elements stripe={stripePromise}>
                                        <CheckoutForm
                                            options={options}
                                        />
                                    </Elements>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row style={{marginTop:"0%"}}>
                        <Col></Col>
                        {/*<Col xs={10} style={{background:"red"}}>*/}
                        <Col xs={10} className={"about_text d-flex justify-content-center"}>
                            <Card style={{ width: '18rem' }} className={"card"}>
                                <Card.Body>
                                    <Card.Title className={"title_card"}>Interac eTransfer</Card.Title>
                                    <Card.Text className={"main_text_card"}>
                                        <br/>
                                        In memo section please specify the category for your donation:
                                        <br/>
                                        <br/>
                                        Email: protectme.intrelief@gmail.com
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush" >
                                    <ListGroupItem className={"main_text_card"}>1 - Protection Equipment</ListGroupItem>
                                    <ListGroupItem className={"main_text_card"}>2 - Military Supplies</ListGroupItem>
                                    <ListGroupItem className={"main_text_card"}>3 - Medical Supplies</ListGroupItem>
                                    <ListGroupItem className={"main_text_card"}>4 - Animal Rescue K-9</ListGroupItem>
                                </ListGroup>
                                <Card.Body>
                                    <Card.Text className={"main_text_card"}>
                                        For Businesses: <br/> Please include the Company name and address
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col></Col>
                    </Row>


                </Container>
    );
};
export default DonateMobile;